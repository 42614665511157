/* eslint-disable consistent-default-export-name/default-export-match-filename */
import type { BrowserOptions, EdgeOptions, NodeOptions } from '@sentry/nextjs'
type Options = BrowserOptions & NodeOptions & EdgeOptions

const enabled =
  process.env.NEXT_PUBLIC_ENABLE_SENTRY === '1' ||
  (!!process.env.NEXT_PUBLIC_SENTRY_DSN &&
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT !== 'test')
const debug =
  process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT?.includes('staging') || process.env.NEXT_PUBLIC_ENABLE_SENTRY === '1'

const baseConfig = {
  enabled,
  debug,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost:?([0-9]+)?',
    /^https?:\/\/(www|secure|api)?\.betterplace\.org/,
    /^https?:\/\/(www|secure|api)?\.betterplace\.dev/,
    /^https?:\/\/(www|secure|api)?\.bp42\.com/,
  ],
} satisfies Partial<Options>

export default baseConfig
